import * as React from 'react';
import BottomSheet from '@gorhom/bottom-sheet';
import {type UpgradeToLevelSmartProps} from '../../../smarts';

export const UpgradeToLevelBottomSheet: React.FC<
  UpgradeToLevelSmartProps &
    Omit<React.ComponentProps<typeof BottomSheet>, 'children'>
> = () => {
  throw new Error('Not implemented');
};
