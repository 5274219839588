import * as React from 'react';
import {Box, type BoxProps, Heading} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {FeatureList, Illustration} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {IconName} from '@youtoken/ui.icons/src/Icon';

export const UpgradeYourLevelSurface: React.FC<BoxProps> = boxProps => {
  const {t} = useTranslation();

  const {
    authMe: {
      data: {
        hasEverDeposited: isDepositRequirementPassed,
        kycResult,
        addressResult,
      },
      products: {
        miner: {isEnabled: isMinerEnabled},
      },
    },
    loyalty: {getLevel},
  } = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    loyalty: getResourceDescriptor(LoyaltyResource, {}),
  });

  const {
    level: secondLevelNumber,
    name: secondLevelName,
    requiredDepositVisibleFormatted,
  } = getLevel(2)!;

  const {level: thirdLevelNumber, name: thirdLevelName} = getLevel(3)!;

  const isVerificationRequirementPassed =
    kycResult === 'ACCEPT' && addressResult === 'ACCEPT';

  const handlePress = () => {
    if (isDepositRequirementPassed) {
      return SHARED_ROUTER_SERVICE.navigate('MultiHODL');
    }
    if (isVerificationRequirementPassed) {
      return SHARED_ROUTER_SERVICE.navigate('WalletsList', {});
    }
    return SHARED_ROUTER_SERVICE.navigate('IdentityVerificationModal', {});
  };

  const buttonText = React.useMemo(() => {
    if (isDepositRequirementPassed) {
      return t('surface.miner.upgrade_modal.volume.trade_action');
    }
    if (isVerificationRequirementPassed) {
      return t('surface.miner.upgrade_modal.deposit_action');
    }
    return t('surface.miner.upgrade_modal.action');
  }, [t, isDepositRequirementPassed, isVerificationRequirementPassed]);

  return (
    <Box flexDirection="column" alignItems="center" {...boxProps}>
      <Illustration name="illustration-14" style={{width: 200, height: 200}} />
      <Heading variant="$heading-02" my={24}>
        {t('surface.loyalty.next_level.upgrade')}
      </Heading>
      <Box alignSelf="stretch" mb={32}>
        <FeatureList
          size="medium"
          items={
            [
              {
                icon: 'verified' as IconName,
                title: isMinerEnabled
                  ? t('surface.miner.upgrade_modal.p1.title')
                  : t('surface.loyalty.next_level_requirement.2', {
                      amount: requiredDepositVisibleFormatted,
                    }),
                children: isMinerEnabled
                  ? t('surface.miner.upgrade_modal.p1.description')
                  : t(
                      'surface.miner.upgrade_modal.upgrade_to_level.description',
                      {level: secondLevelNumber, levelName: secondLevelName}
                    ),
              },
              isMinerEnabled && {
                icon: 'fiat_deposit' as IconName,
                title: t('surface.loyalty.next_level_requirement.2', {
                  amount: requiredDepositVisibleFormatted,
                }),
                children: t('surface.miner.upgrade_modal.p2.description'),
              },
              {
                icon: 'hodl' as IconName,
                title: t('surface.miner.upgrade_modal.p3.title'),
                children: isMinerEnabled
                  ? t('surface.miner.upgrade_modal.p3.description')
                  : t(
                      'surface.miner.upgrade_modal.upgrade_to_level.description',
                      {level: thirdLevelNumber, levelName: thirdLevelName}
                    ),
              },
              // this as any is shit
              // but somehow it still show error in check (BUT NOT IDE) otherwise
            ].filter(item => item !== false) as any
          }
        />
      </Box>
      <Button onPress={handlePress} alignSelf="stretch">
        {buttonText}
      </Button>
    </Box>
  );
};

export const UpgradeYourLevelSurfaceCell = cell(UpgradeYourLevelSurface);
