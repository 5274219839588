import * as React from 'react';
import {Modal} from '@youtoken/ui.modal';
import {ReferralsSmart} from '../../../smarts';

export const ReferralsModal: React.FC<
  React.ComponentProps<typeof Modal>
> = modalProps => {
  return (
    <Modal {...modalProps}>
      <ReferralsSmart contentProps={{borderWidth: 0}} showShareRefCode />
    </Modal>
  );
};
