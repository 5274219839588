import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Button} from '@youtoken/ui.buttons';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('IdentityVerificationModal', {});
};

export const RewardsOverviewStatusActionVerify: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Button size="medium" color="interactive" mt={24} onPress={handlePress}>
      {t('surface.referral_program.rewards.verify_action')}
    </Button>
  );
});
